import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: JSON.parse(localStorage.getItem('cartItems')) || [],
    sum: 0,
    currencies: [],
    activeCurrency: JSON.parse(sessionStorage.getItem('activeCurrency')) || null,
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItem: (state, action) => {
            const itemIndex = state.items.findIndex(item => item.productId === action.payload.productId);
            if (itemIndex >= 0) {
                state.items[itemIndex].count += action.payload.count;
            } else {
                state.items.push({ ...action.payload });
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        removeItem: (state, action) => {
            state.items = state.items.filter(item => item.productId !== action.payload);
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        clearCart: (state) => {
            state.items = [];
            localStorage.removeItem('cartItems');
        },
        setSum: (state) => {
            if (state.activeCurrency && state.activeCurrency.amount) {
                state.sum = state.items.reduce((acc, item) => acc + (item.price * state.activeCurrency.amount), 0);
            } else {
                state.sum = state.items.reduce((acc, item) => acc + item.price, 0);
            }
        },
        updateItemCount: (state, action) => {
            const { productId, count } = action.payload;
            if (count > 0) {
                state.items = state.items.map(item =>
                    item.productId === productId ? { ...item, count: count } : item
                );
            } else {
                state.items = state.items.filter(item => item.productId !== productId);
            }
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        setCurrencies: (state, action) => {
            state.currencies = action.payload.map(currency => {
                let symbol;
                switch (currency.name) {
                    case 'KZT':
                        symbol = '₸';
                        break;
                    case 'RUB':
                        symbol = '₽';
                        break;
                    case 'USD':
                        symbol = '$';
                        break;
                    default:
                        symbol = currency.name;
                }
                return {
                    ...currency,
                    symbol,
                };
            });
        },
        setActiveCurrency: (state, action) => {
            const selectedCurrency = state.currencies.find(
                currency => currency.name === action.payload.name
            );
            if (selectedCurrency) {
                state.activeCurrency = selectedCurrency;
                sessionStorage.setItem('activeCurrency', JSON.stringify(selectedCurrency));
            }
        },
    }
});

export const { setItems, addItem, removeItem, setSum, updateItemCount, setCurrencies, setActiveCurrency, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
