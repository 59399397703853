import { useTranslation } from "react-i18next";
import Spoiler from "../components/spoiler/Spoiler";
import {useEffect, useRef} from "react";


const Support = (props) => {
    const { t } = useTranslation();
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                alert(t('copied')); // Уведомление об успешном копировании
            })
            .catch(() => {
                alert(t('copied-error')); // Уведомление об ошибке копирования
            });
    };

    return (
        <main className="main" ref={topRef}>
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t('support')}</div>

                    <div className="frame">
                        <Spoiler
                            question={t("question1")}
                            text={t("answer1")} />

                        <Spoiler
                            question={t("question2")}
                            text={t("answer2")} />

                        {/*<Spoiler*/}
                        {/*    question={t("question3")}*/}
                        {/*    text={t("answer3")} />*/}

                        <Spoiler
                            question={t("question4")}
                            text={t("answer4")} />

                        <Spoiler
                            question={t("question5")}
                            text={t("answer5")} />

                        <Spoiler
                            question={t("question6")}
                            text={t("answer6")} />

                        <Spoiler
                            question={t("question7")}
                            text={t("answer7")} />

                    </div>

                    <div className="divider"></div>

                    <div className="frame" id="contact-point">
                        <div className="frame__title">{t('contacts')}</div>
                        <div className="frame__text main-text">
                            <p dangerouslySetInnerHTML={{ __html: t('support-text') }} />
                        </div>
                        <div className="frame__table">
                            <div className="frame__item">
                                <div className="frame__head">{t('city')}</div>
                                <div className="frame__data">{t('address')}</div>
                            </div>
                            <div className="frame__item">
                                <div className="frame__head">{t('support')}</div>
                                <div onClick={() => copyToClipboard(t('help-email'))} className="frame__data">{t('help-email')}</div>
                            </div>
                            <div className="frame__item">
                                <div className="frame__head">{t('partners')}</div>
                                <div className="frame__data">{t('partners-email')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Support;