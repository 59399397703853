import Plus from "../../images/status/plus.svg";
import Decrease from "../../images/status/decrease.svg";
import { useContext, useEffect, useState } from "react";
import Button from "../button/Button";
import { Cart, RefreshBlue } from "../../images/system";
import { AuthContext } from "../../providers/AuthProvider";
import useMainService from "../../services/MainService";
import { useBasketService } from "../../services/BasketService";
import { Pubg } from "../../images/backgrounds";
import { useTranslation } from "react-i18next";
import { useCart } from "../../providers/CartProvider";
import { useDispatch, useSelector } from "react-redux";
import { addItem, updateItemCount } from "../../features/cart/cartSlice";
import NotificationItem from "../notificationItem/NotificationItem";

const CartItem = (props) => {
    const { items, setItems } = useCart();
    const [count, setCount] = useState(props.startCount || 1);
    const [seconds, setSeconds] = useState(5);
    const [product, setProduct] = useState(null);
    const { productId } = props;
    const { isAuth } = useContext(AuthContext);
    const { getProductById } = useMainService();
    const { addItemToBasket, removeItemToBasket, checkBasket } = useBasketService();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [timerActive, setTimerActive] = useState(false);

    // Получаем данные из Redux
    const { currencies, activeCurrency } = useSelector(state => state.cart);

    // Устанавливаем валюту по умолчанию на KZT, если активная валюта отсутствует
    const currentCurrency = activeCurrency || currencies.find(currency => currency.name === 'KZT') || { symbol: '₸', amount: 1 };

    // Получаем курс и символ валюты
    const currencyAmount = currentCurrency.amount;
    const currencySymbol = currentCurrency.symbol;

    const isUSD = currencySymbol === "$";

    useEffect(() => {
        getProductById(productId)
            .then(data => setProduct(data))
            .catch(err => console.error(err));
    }, [productId]);

    const convertPrice = (price, amount) => {
        return price
            ? isUSD
                ? (price * amount).toFixed(2)
                : Math.round(price * amount)
            : null;
    };

    const price = product && !isNaN(product.price)
        ? convertPrice(product.price, currencyAmount)
        : "Цена не доступна";

    const priceWithDiscount = product?.priceWithDiscount && !isNaN(product?.priceWithDiscount)
        ? convertPrice(product.priceWithDiscount, currencyAmount).toLocaleString('ru-RU')
        : null;

    const updateBasket = async (basketId) => {
        const updatedBasket = await checkBasket(basketId);
        if (updatedBasket && Array.isArray(updatedBasket.basketItems)) {
            setItems(updatedBasket.basketItems);
        }
    };

    const handleChangeCount = async (newCount) => {
        setCount(newCount);

        const basketId = localStorage.getItem('basketId');
        if (basketId && newCount !== 0) {
            const currentItem = items.find(item => item.productId === productId);
            const currentCount = currentItem ? currentItem.count : 0;
            const difference = newCount - currentCount;

            try {
                if (difference > 0) {
                    await addItemToBasket(basketId, productId, difference);
                } else {
                    await removeItemToBasket(basketId, productId, Math.abs(difference));
                }
                updateBasket(basketId);
            } catch (error) {
                console.error("Error updating item quantity on server:", error);
            }
        }

        dispatch(updateItemCount({ productId, count: newCount }));

        if (newCount === 0) {
            setSeconds(5);
            startDeleteTimer();
        }
    };

    const startDeleteTimer = () => {
        setTimerActive(true);
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 1) {
                    clearInterval(timer);
                    setTimerActive(false);
                    deleteItem();
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };

    const deleteItem = async () => {
        const basketId = localStorage.getItem('basketId');
        if (basketId) {
            try {
                const currentItem = items.find(item => item.productId === productId);
                const currentCount = currentItem ? currentItem.count : 0;
                if (currentCount > 0) {
                    await removeItemToBasket(basketId, productId, currentCount);
                    updateBasket(basketId);
                }
            } catch (error) {
                console.error(`Error removing product ${productId}:`, error);
            }
        }
        dispatch(updateItemCount({ productId, count: 0 }));
    };

    const button = count === 0 ? (
        <Button text={t("add")} src={Cart} extra={"btn-strong"} onClick={() => {
            if (isAuth) handleChangeCount(1);
        }} />
    ) : (
        <div className="counter">
            <div onClick={() => handleChangeCount(count > 0 ? count - 1 : 0)} className="counter__icon">
                <img src={Decrease} alt="decrease count" />
            </div>
            <div className="counter__text">{count}</div>
            <div onClick={() => handleChangeCount(count + 1)} className="counter__icon plus">
                <img src={Plus} alt="increase count" />
            </div>
        </div>
    );

    return (
        <div className={"bought-card" + (count === 0 ? " deleted" : "")}>
            <div className="bought-card__img">
                <img src={product?.images || Pubg} alt="" />
            </div>
            <div className="bought-card__content">
                <div className="bought-card__text">{product?.name || ""}</div>
                <section className="path">
                    <div className="path__row">
                        {priceWithDiscount && priceWithDiscount !== price && (
                            <div className="bought-card__text old">{priceWithDiscount}</div>
                        )}
                        <div className="bought-card__text">{Number(price).toLocaleString('ru-RU')}</div>
                        <div className="path__link">{currencySymbol}</div>
                    </div>
                </section>
            </div>
            <div className="bought-card__btn">{button}</div>
            <NotificationItem removeItem={deleteItem} />
        </div>
    );
};

export default CartItem;
