import ArrowLeft from "../../images/arrows/arrow-left-long.svg";
import ArrowLeftHover from "../../images/arrows/arrow-left-long-hover.svg";
import ArrowRight from "../../images/arrows/arrow-right-long.svg";
import ArrowRightHover from "../../images/arrows/arrow-right-long-hover.svg";
import SliderItem from "../sliderItem/SliderItem";
import { useEffect, useMemo, useRef, useState } from "react";

const SimpleSlider = (props) => {
    const [current, setCurrent] = useState(0);
    const [sizeItem, setSizeItem] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [walk, setWalk] = useState(0);
    const parentRef = useRef(null);

    const { items } = props;

    useEffect(() => setCurrent(0), [items, sizeItem]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (current > (-2 + sizeItem)) {
                setCurrent(current => current - 1);
            } else {
                setCurrent(0);
            }
        }, 3000);

        return () => clearInterval(timer);
    }, [current, sizeItem]);

    function forward() {
        if (current < 0) {
            setCurrent(current => current + 1);
        } else {
            // Если находимся на последнем слайде, перейти на первый
            setCurrent(-items.length + 2);
        }
    }

    function back() {
        if (current > (2 - items.length)) {
            setCurrent(current => current - 1);
        } else {
            // Если находимся на первом слайде, перейти на последний
            setCurrent(0);
        }
    }

    const array = items.length > 3 ? [items[items.length - 1], ...items] : items;

    const children = useMemo(() => array.map((item, index) => {
        if (index === 0) {
            return (
                //Элемент слайдера
                <SliderItem
                    key={index}
                    id={item.id}
                    setSize={val => setSizeItem(val)}
                    sizeItem={sizeItem}
                    parentRef={parentRef}
                    background={item.images.imagePaths[0]}
                    price={"1 499"}
                    name={item.name}
                    first={true}
                    margin={current}
                    setCurrent={val => setCurrent(val)}
                    count={items.length}
                />
            );
        } else {
            return (
                <SliderItem
                    id={item.id}
                    key={index}
                    background={item.images.imagePaths[0]}
                    name={item.name}
                />
            );
        }
    }), [array, sizeItem, current, items.length]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - parentRef.current.offsetLeft);
    }

    const handleTouchStart = (e) => {
        setIsDragging(true);
        setStartX(e.touches[0].pageX - parentRef.current.offsetLeft);
    }

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - parentRef.current.offsetLeft;
        const walk = x - startX;
        setWalk(walk);
    }

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const x = e.touches[0].pageX - parentRef.current.offsetLeft;
        const walk = x - startX;
        setWalk(walk);
    }

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
        if (walk > 50) {
            forward();
        } else if (walk < -50) {
            back();
        }
    }

    const handleTouchEnd = () => {
        setIsDragging(false);
        if (walk > 50) {
            forward();
        } else if (walk < -50) {
            back();
        }
    }

    const handleWheel = (e) => {
        e.preventDefault();
        if (e.deltaY > 0) {
            back();
        } else {
            forward();
        }
    }

    useEffect(() => {
        const ref = parentRef.current;
        if (ref) {
            ref.addEventListener("wheel", handleWheel);
        }
        return () => {
            if (ref) {
                ref.removeEventListener("wheel", handleWheel);
            }
        };
    }, [current]);

    return (
        <div
            ref={parentRef}
            className="banner"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="banner__row">
                <div onClick={forward} className="banner__arrow arrow banner__arrow-left">
                    <div className="arrow-content-banner">
                        <div className="arrow-icon">
                            <img className="arrow-icon__normal" src={ArrowLeft}
                                 alt="left arrow slide" />
                            <img className="arrow-icon__hover" src={ArrowLeftHover}
                                 alt="left arrow slide" />
                        </div>
                    </div>
                </div>
                <div onClick={back} className="banner__arrow arrow banner__arrow-right">
                    <div className="arrow-content-banner">
                        <div className="arrow-icon">
                            <img className="arrow-icon__normal" src={ArrowRight}
                                 alt="right arrow slide" />
                            <img className="arrow-icon__hover" src={ArrowRightHover}
                                 alt="right arrow slide" />
                        </div>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

export default SimpleSlider;
