import Plus from "../../images/status/plus.svg";
import Decrease from "../../images/status/decrease.svg";
import { useContext, useEffect, useState } from "react";
import Button from "../button/Button";
import { Cart, RefreshBlue } from "../../images/system";
import { AuthContext } from "../../providers/AuthProvider";
import { PopupContext } from "../../providers/PopupProvider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../features/cart/cartSlice";

const BoughtCard = (props) => {
    const [count, setCount] = useState(0);
    const { src, itemId, name, text, oldPrice, price, deleted, time, id, status, startCount, cnt } = props;
    const { isAuth } = useContext(AuthContext);
    const { isShow, setShow } = useContext(PopupContext);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Логирование пропсов для отладки
    console.log('BoughtCard props:', props);

    // Получаем активную валюту из Redux
    const { activeCurrency } = useSelector(state => state.cart);
    const currencySymbol = activeCurrency?.symbol || '₸';

    // Преобразуем price в число (убираем пробелы и заменяем запятую на точку)
    const numericPrice = parseFloat(price.toString().replace(/\s/g, '').replace(',', '.')) || 0;

    // Находим курс валюты по активной валюте
    const currencyRate = activeCurrency?.amount || 1;

    // Форматирование цены в зависимости от валюты
    const formatPrice = (value) => {
        return currencySymbol === "$" ? (value * currencyRate).toFixed(2) : Math.round(value * currencyRate);
    };

    const formattedOldPrice = formatPrice(oldPrice);
    const formattedPrice = formatPrice(numericPrice);

    // Функция для вычисления процента скидки
    const calculateDiscount = (oldPrice, newPrice) => {
        if (!oldPrice || oldPrice <= newPrice) return null; // Если старая цена не указана или скидки нет
        const discount = ((oldPrice - newPrice) / oldPrice) * 100;
        return Math.round(discount); // Возвращаем округленный процент
    };

    const discountPercent = calculateDiscount(formattedOldPrice, formattedPrice);

    const statusComp = time ? (
        <section className="path">
            <div className="path__row">
                <div className={"status " + (status === 1 ? "" : status === 2 ? "yellow" : "red")}>
                    {status === 1 ? t('payed') : status === 2 ? t('waited') : t('not-payed')}
                </div>
                <div className="path__link">•</div>
                <div className="path__link">{id}</div>
                <div className="path__link">•</div>
                <div className="path__link">{time}</div>
            </div>
        </section>
    ) : null;

    useEffect(() => setCount(0), [cnt]);

    useEffect(() => {
        if (!isAuth) setCount(0);
    }, [isAuth]);

    useEffect(() => {
        if (isNaN(startCount)) {
            const basketItems = JSON.parse(localStorage.getItem('basketItems'));
            if (basketItems) {
                basketItems.forEach(item => {
                    if (item.id === itemId && item.count !== 0) setCount(item.count);
                });
            }
        }
    }, [itemId]);

    useEffect(() => {
        if (!isNaN(startCount)) {
            setCount(startCount);
        }
    }, [startCount]);

    useEffect(() => {
        let basketItems = JSON.parse(localStorage.getItem('basketItems')) || [];
        const itemIndex = basketItems.findIndex(item => item.id === itemId);

        if (count === 0 && itemIndex >= 0) {
            basketItems.splice(itemIndex, 1);
        } else if (itemIndex >= 0) {
            basketItems[itemIndex].count = count;
        } else if (count !== 0) {
            basketItems.push({ id: itemId, name: text, price: numericPrice, count });
        }

        localStorage.setItem('basketItems', JSON.stringify(basketItems));
    }, [count, itemId, text, numericPrice]);

    const addToCart = () => {
        if (isAuth) {
            setCount(1);
            dispatch(addItem({ productId: itemId, name: text, price: numericPrice, count: 1 }));
        } else {
            setShow(true);
        }
    };

    const plusToCart = () => {
        if (isAuth) {
            setCount(count => count + 1);
            dispatch(addItem({ productId: itemId, name: text, price: numericPrice, count: 1 }));
        } else {
            setShow(true);
        }
    };

    const minusToCart = () => {
        if (isAuth) {
            setCount(count => Math.max(0, count - 1));
            dispatch(addItem({ productId: itemId, name: text, price: numericPrice, count: -1 }));
        } else {
            setShow(true);
        }
    };

    const button = count === 0 ? (
        <Button text={t('add')} src={Cart} extra={"btn-strong"} onClick={addToCart} />
    ) : (
        <div className="counter">
            <div onClick={minusToCart} className="counter__icon">
                <img src={Decrease} alt="decrease count" />
            </div>
            <div className="counter__text">{count}</div>
            <div onClick={plusToCart} className="counter__icon plus">
                <img src={Plus} alt="increase count" />
            </div>
        </div>
    );

    return (
        <div className={"bought-card" + (deleted ? " deleted" : "")}>
            <div className="bought-card__img">
                <img src={src} alt={name} />
            </div>
            <div className="bought-card__content">
                {statusComp}
                {name && <div className="bought-card__small">{name}</div>}
                <div className="bought-card__text">{text}</div>
                <section className="path">
                    <div className="path__row">
                        {discountPercent !== null && discountPercent > 0 && (
                            <div className="discount">-{discountPercent}%</div>
                        )}
                        {oldPrice !== price && (
                            <div className="bought-card__text old">{formattedOldPrice.toLocaleString('ru-RU')}</div>
                        )}
                        {/*<div className="discount">%</div>*/}
                        <div className="bought-card__text">{formattedPrice.toLocaleString('ru-RU')}</div>
                        <div className="path__link">{currencySymbol}</div>
                    </div>
                </section>
            </div>
            <div className="bought-card__btn">{button}</div>
            <div className="bought-card__deleted">
                <p>{t('cancel')} 5..</p>
                <img src={RefreshBlue} alt="refresh" />
            </div>
        </div>
    );
};

export default BoughtCard;
