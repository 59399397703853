import "../scss/catalog.scss";
import { useTranslation } from "react-i18next";
import CategoriesList from "../components/categoriesList/CategoriesList";
import Path from "../components/path/Path";
import {RightLow} from "../images/arrows";
import {Epoint, EpointText, Platron} from "../images/components";
import {Visa} from "../images/payment";


const Guarantee = () => {
    const pathValues = ["Главная", "Guarantee"];
    const {t} = useTranslation();

    return (
        <main className="main">
            <CategoriesList/>
            <Path values={pathValues} icon={RightLow}/>
            <div className="main__container">
                <div className="title">{t('guarantee-link')}</div>
            </div>
            <div className="guarantee-container">
                <div className="guarantee-image-container">
                    <img src={Platron} alt="Platron"/>
                    <img src={Epoint} alt="Epoint"/>
                    <img src={Visa} alt="Visa"/>
                </div>
                <div>
                    <div className="news-title-2">{t('guarantee-title1')}</div>
                    <div className="main-text">{t('guarantee-title11')}</div>
                    <div className="news-title-2">{t('guarantee-title2')}</div>
                    <div className="main-text">{t('guarantee-title21')}</div>
                    <div className="news-title-2">{t('guarantee-title3')}</div>
                    <div className="main-text">{t('guarantee-title31')}</div>
                    <div className="news-title-2">{t('guarantee-title4')}</div>
                    <div className="main-text">{t('guarantee-title41')}</div>
                    <div className="news-title-2">{t('guarantee-title5')}</div>
                    <div className="main-text">{t('guarantee-title51')}</div>
                    <div className="news-title-2">{t('guarantee-title6')}</div>
                    <div className="main-text">{t('guarantee-title61')}</div>
                </div>
            </div>
        </main>
    );
}

export default Guarantee;