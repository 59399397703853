import { useTranslation } from "react-i18next";
import {useEffect, useRef} from "react";



const PlatronDocumentation = () => {
    const {t} = useTranslation();
    const topRef = useRef(null);

    useEffect(() => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);
    return (
        <div className="main" ref={topRef}>
            <div className="information">
                <div className="information__row">
                    <div className="news-title">{t("platron-document-header")}</div>
                    <div className="news-title-2">{t('platron-document-1')}</div>
                    <div className="main-text">{t('platron-document-11')}</div>
                    <div className="main-text">{t('platron-document-12')}</div>
                    <div className="main-text">{t('platron-document-13')}</div>
                    <div className="main-text">{t('platron-document-14')}</div>
                    <div className="main-text">{t('platron-document-15')}</div>
                    <div className="main-text">{t('platron-document-16')}</div>
                    <div className="news-title-2 space">{t('platron-document-2')}</div>
                    <div className="main-text">{t('platron-document-21')}</div>
                    <div className="main-text">{t('platron-document-22')}</div>
                    <div className="main-text">{t('platron-document-23')}</div>
                    <div className="main-text">{t('platron-document-24')}</div>
                    <div className="main-text">{t('platron-document-25')}</div>
                    <div className="main-text">{t('platron-document-26')}</div>
                    <div className="news-title-2 space">{t('platron-document-3')}</div>
                    <div className="main-text">{t('platron-document-31')}</div>
                    <div className="news-title-2 space">{t('platron-document-4')}</div>
                    <div className="main-text">{t('platron-document-41')}</div>
                </div>
            </div>
        </div>
    );
}

export default PlatronDocumentation;