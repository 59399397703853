import { Link } from "react-router-dom";
import { Like as LikeIcon, LikeHover as LikeIconHover, LikeRed } from "../../images/system";
import { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { PopupContext } from "../../providers/PopupProvider";
import { useSelector } from "react-redux";

const GameCard = (props) => {
    const [like, setLike] = useState(false);
    const { id, name, oldPrice, price, big, src, isMouseDown } = props;
    const { isAuth } = useContext(AuthContext);
    const { setShow } = useContext(PopupContext);

    // Получаем данные из Redux
    const { currencies, activeCurrency } = useSelector(state => state.cart);

    // Устанавливаем валюту по умолчанию на KZT, если активная валюта отсутствует
    const currentCurrency = activeCurrency || currencies.find(currency => currency.name === 'KZT') || { symbol: '₸', amount: 1 };

    // Получаем курс и символ валюты
    const currencyAmount = currentCurrency.amount;
    const currencySymbol = currentCurrency.symbol;

    // Функция для преобразования цен
    const convertPrice = (price, amount, isUSD) => {
        return price
            ? isUSD
                ? (price * amount).toFixed(2)
                : Math.round(price * amount)
            : null;
    };

    // Определяем, является ли валюта долларом
    const isUSD = currencySymbol === "$";

    const convertedPrice = convertPrice(price, currencyAmount, isUSD);
    const convertedOldPrice = convertPrice(oldPrice, currencyAmount, isUSD);

    const handleLink = (e) => {
        if (isMouseDown) {
            e.preventDefault();
        }
    };

    const handleLikeClick = (e) => {
        e.preventDefault();
        if (isAuth) {
            setLike(prev => !prev);
        } else {
            setShow(true);
        }
    };

    return (
        <Link
            to={`/catalog/${id}`}
            className={"game-card " + (big ? "no-hover big" : "")}
            onClick={handleLink} draggable="false"
        >
            <div className="game-card__img">
                <img src={src || ''} alt="" draggable="false" />
            </div>
            <div className="game-card__row">
                <div className="game-card__content">
                    <div className="game-card__title">{name}</div>
                    <div className="game-card__price">
                        {convertedOldPrice && convertedOldPrice !== convertedPrice && (
                            <div className="game-card__price-old">{Number(convertedOldPrice).toLocaleString('ru-RU')}</div>
                        )}
                        <div className="game-card__price-now">{Number(convertedPrice).toLocaleString('ru-RU')}</div>
                        <div className="game-card__price-value">{currencySymbol}</div>
                    </div>
                </div>
                <div className="game-card__notifications">
                    {/* Отображение уведомлений о скидке на карточке */}
                </div>
                <div onClick={handleLikeClick} className="game-card__fav arrow">
                    <div className="arrow-content">
                        <div className="arrow-icon">
                            <img className="arrow-icon__normal" src={!like ? LikeIcon : LikeRed} alt="add favourite game" />
                            <img className="arrow-icon__hover" src={!like ? LikeIcon : LikeRed} alt="add favourite game" />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default GameCard;
