import React, { useEffect, useState } from 'react';
import ArrowIcon from "../../images/arrows/arrow-slide.svg";
import Menu from '../header/menu/Menu';
import MenuItem from '../header/menu/MenuItem';
import { useDispatch, useSelector } from "react-redux";
import { setActiveCurrency, setCurrencies } from "../../features/cart/cartSlice";

const CurrencyMenu = () => {
    const dispatch = useDispatch();

    const currencies = useSelector(state => state.cart.currencies);

    const activeCurrency = useSelector(state => state.cart.activeCurrency);

    const [active, setActive] = useState(() => {
        const savedCurrency = JSON.parse(sessionStorage.getItem('activeCurrency'));
        return savedCurrency ? savedCurrency.name : 'KGS';
    });

    useEffect(() => {
        if (currencies.length > 0 && !currencies.some(currency => currency.name === 'USD')) {
            const updatedCurrencies = [...currencies, { name: 'USD', symbol: '$', amount: 1 }];
            dispatch(setCurrencies(updatedCurrencies));
        }

        const savedCurrency = JSON.parse(sessionStorage.getItem('activeCurrency'));
        if (savedCurrency && currencies.some(currency => currency.name === savedCurrency.name)) {
            if (!activeCurrency || activeCurrency.name !== savedCurrency.name) {
                setActive(savedCurrency.name);
                dispatch(setActiveCurrency(savedCurrency));
            }
        } else if (currencies.length > 0) {
            const defaultCurrency = currencies.find(currency => currency.name === 'USD') || currencies[0];
            sessionStorage.setItem('activeCurrency', JSON.stringify(defaultCurrency));
            dispatch(setActiveCurrency(defaultCurrency));
            setActive(defaultCurrency.name);
        }
    }, [currencies, dispatch, activeCurrency]);

    const changeCurrency = (currencyName) => {
        const newCurrency = currencies.find(currency => currency.name === currencyName);
        if (newCurrency) {
            sessionStorage.setItem('activeCurrency', JSON.stringify(newCurrency));
            dispatch(setActiveCurrency(newCurrency));
            setActive(newCurrency.name);
        }
    };

    // Фильтруем валюты, оставляем только KGS и USD
    const filteredCurrencies = currencies.filter(currency =>
        ['KZT', 'RUB', 'USD'].includes(currency.name)
    );

    return (
        <div className='lng'>
            <div className="lng__active">
                {filteredCurrencies.find(currency => currency.name === active)?.symbol || '$'}
            </div>
            <div className="navigation__button">
                <img src={ArrowIcon} alt="arrow slide down" />
            </div>
            <div className="lng__body">
                <Menu>
                    {filteredCurrencies.map((currency) => (
                        <MenuItem
                            key={currency.name}
                            text={currency.symbol}
                            onClick={() => changeCurrency(currency.name)}
                        />
                    ))}
                </Menu>
            </div>
        </div>
    );
};

export default CurrencyMenu;
